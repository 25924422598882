









































import { defineComponent } from "@vue/composition-api";
import { Button, CellGroup, Field, Form, Switch, Toast } from "vant";
import axios from "@/helpers/axios";
import LocationPicker from "@/components/picker/location-picker.vue";

export default defineComponent({
  components: {
    [LocationPicker.name]: LocationPicker,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [Switch.name]: Switch,
    [Button.name]: Button
  },
  data() {
    return {
      shipMethodId: 0,
      platformId: 0,
      platformName: "",
      businessId: 0,
      businessName: "",
      siteId: 0,
      collectEnable: false,
      collectPrice: 0,
      collectLatitude: 0.0,
      collectLongitude: 0.0,
      collectAddress: "",
      collectProvince: "",
      collectCity: "",
      collectArea: "",
      collectPhone: "",
      showLocationPicker: false,
      deliveryEnable: false,
      deliveryPrice: 0
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = await axios
        .post("/api/manage/card/getShipMethod")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.shipMethodId = data.id;
      this.platformId = data.platformId;
      this.platformName = data.platformName;
      this.businessId = data.businessId;
      this.businessName = data.businessName;
      this.siteId = data.siteId;
      this.collectEnable = data.collectEnable;
      this.collectPrice = data.collectPrice / 100;
      this.collectPhone = data.collectPhone;
      this.collectAddress = data.collectAddress;
      this.deliveryEnable = data.deliveryEnable;
      this.deliveryPrice = data.deliveryPrice / 100;
      this.collectProvince = data.collectProvince;
      this.collectCity = data.collectCity;
      this.collectArea = data.collectArea;
      this.collectLatitude = data.collectLatitude;
      this.collectLongitude = data.collectLongitude;
    },
    async saveData() {
      const res = await axios
        .post("/api/manage/card/saveShipMethod", {
          shipMethodId: this.shipMethodId,
          collectEnable: this.collectEnable,
          collectPrice: Math.round(this.collectPrice * 100),
          collectPhone: this.collectPhone,
          collectAddress: this.collectAddress,
          collectProvince: this.collectProvince,
          collectCity: this.collectCity,
          collectArea: this.collectArea,
          collectLatitude: this.collectLatitude,
          collectLongitude: this.collectLongitude,
          deliveryEnable: this.deliveryEnable,
          deliveryPrice: Math.round(this.deliveryPrice * 100)
        })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Toast.success("保存成功");
      this.getData();
    },
    onPickLocation(location: { latitude: number; longitude: number; detail: { address: string; province: string; city: string; area: string } }) {
      this.collectLatitude = location.latitude;
      this.collectLongitude = location.longitude;
      this.collectAddress = location.detail.address;
      this.collectProvince = location.detail.province;
      this.collectCity = location.detail.city;
      this.collectArea = location.detail.area;
    }
  }
});
